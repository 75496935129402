
const YoutubeSvg = () => {

    const handleClick = () => {
        window.open('https://www.youtube.com/@openfr8', '_blank');
      };

    return (
        <svg onClick={handleClick} className='cursor-pointer fill-slate-500 hover:fill-white' width="42" height="29" viewBox="0 0 42 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.1928 4.58121C39.9612 3.70887 39.5044 2.9127 38.8683 2.27243C38.2321 1.63215 37.4389 1.17026 36.5681 0.932989C33.3704 0.0703126 20.553 0.0703125 20.553 0.0703125C20.553 0.0703125 7.73248 0.0703126 4.53801 0.932989C3.666 1.16928 2.87147 1.63074 2.23413 2.27108C1.59679 2.91142 1.13906 3.70811 0.90687 4.58121C0.0527343 7.79917 0.0527344 14.5105 0.0527344 14.5105C0.0527344 14.5105 0.0527343 21.2251 0.90687 24.4399C1.14037 25.3049 1.59875 26.0927 2.23541 26.7232C2.87208 27.3537 3.66432 27.8043 4.53161 28.0294C7.72928 28.892 20.5466 28.892 20.5466 28.892C20.5466 28.892 33.3672 28.892 36.5617 28.0294C37.429 27.8043 38.2212 27.3537 38.8579 26.7232C39.4945 26.0927 39.9529 25.3049 40.1864 24.4399C41.0406 21.2219 41.0406 14.5105 41.0406 14.5105C41.0406 14.5105 41.0406 7.79597 40.1864 4.58121H40.1928ZM16.3571 20.6091V8.41735L27.0722 14.5137L16.3571 20.6091Z" />
        </svg>

    )
}

export default YoutubeSvg