
const FacebookSvg = () => {

    const handleClick = () => {
        window.open('https://www.facebook.com/openfr8/', '_blank');
      };

    return (
        <svg onClick={handleClick} className='cursor-pointer fill-slate-500 hover:fill-white' width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.7741 14.3942C29.7569 11.6436 28.9531 8.95536 27.4575 6.64684C25.9619 4.33833 23.837 2.50597 21.3337 1.36612C18.8303 0.226274 16.053 -0.173458 13.3298 0.214125C10.6066 0.601708 8.0512 1.76042 5.96527 3.55344C3.87933 5.34647 2.35001 7.69892 1.55788 10.333C0.765744 12.9672 0.743882 15.7729 1.49487 18.4191C2.24586 21.0652 3.73834 23.4412 5.79609 25.2665C7.85383 27.0918 10.3909 28.2902 13.1077 28.7202V18.5859H9.4467V14.3942H13.1077V11.1987C13.1077 7.56861 15.258 5.55712 18.5529 5.55712C19.6338 5.57222 20.7122 5.66678 21.7793 5.84005V9.40714H19.9643C19.6536 9.3669 19.3378 9.39706 19.0404 9.49536C18.7429 9.59367 18.4713 9.75761 18.2458 9.97506C18.0202 10.1925 17.8465 10.4579 17.7374 10.7516C17.6283 11.0452 17.5866 11.3597 17.6154 11.6717V14.3932H21.6128L20.9722 18.5848H17.6122V28.7191C21.0118 28.1678 24.1035 26.4229 26.3325 23.7975C28.5614 21.1721 29.7816 17.8382 29.7741 14.3942Z" />
        </svg>
    )
}

export default FacebookSvg