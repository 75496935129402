const PhoneIosSvg = (props:{className?:string}) => {
    const {className = ''} = props

    const handleClick = () => {
        window.open('https://apps.apple.com/in/app/openfr8-book-online-truck-load/id6544790771', '_blank');
      };

    return (

<svg onClick={handleClick} className={`cursor-pointer fill-slate-500 hover:fill-white ${className}`} width={36} height={36} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M223.817 256H32.1829C14.6286 256 0 242.182 0 224.727C0 224.364 0 224.364 0 224V32C0 14.5455 13.8972 0 31.4515 0C31.8172 0 31.8172 0 32.1829 0H223.817C241.371 0 256 13.8182 256 31.2727C256 31.6364 256 31.6364 256 32V224C256 241.455 242.103 256 224.549 256C224.183 256 223.817 256 223.817 256Z" fill="white"/>
<path d="M223.817 5.0909C238.811 5.0909 250.88 17.0909 250.88 32V224C250.88 238.909 238.811 250.909 223.817 250.909H32.1829C17.1886 250.909 5.12001 238.909 5.12001 224V32C5.12001 17.0909 17.1886 5.0909 32.1829 5.0909H223.817ZM223.817 0H32.1829C14.2629 0 0 14.5455 0 32V224C0 241.455 13.8972 256 31.4515 256C31.8172 256 31.8172 256 32.1829 256H223.817C241.371 256 256 242.182 256 224.727C256 224.364 256 224.364 256 224V32C256 14.1818 241.371 0 223.817 0Z" fill="black"/>
<path d="M186.511 103.636C184.316 105.455 182.122 107.273 179.928 109.091C172.614 116 168.956 125.818 170.054 135.636C170.419 142.182 172.979 148.364 177.002 153.455C180.294 157.455 183.951 160.364 188.339 162.545C190.168 163.636 190.168 163.636 189.436 165.455C185.779 175.273 180.659 184.727 173.711 192.727C171.516 195.273 169.322 197.818 166.762 200C161.642 204 154.694 205.091 148.477 202.909C145.551 201.818 142.625 200.364 139.334 199.636C132.385 197.455 125.071 197.818 118.488 200.727C115.197 202.182 111.539 203.273 107.882 204.364C103.128 205.455 98.0079 204 94.3508 200.727C89.5965 196.727 85.5736 192 82.2822 186.909C73.8708 174.545 68.0193 160.727 65.825 145.818C63.9965 136 64.7279 125.818 67.6536 116C71.3108 104 80.0879 94.5454 91.7908 90.5454C99.1051 88 107.151 88 114.465 90.9091L123.242 94.1818C126.534 95.2727 129.825 95.2727 133.117 94.1818C136.774 92.7273 140.431 91.2727 144.088 90.1818C151.402 87.6363 159.448 87.6363 166.762 89.8181C174.077 92 180.659 96.3636 185.048 102.545C186.145 103.273 186.145 103.273 186.511 103.636Z" fill="black"/>
<path d="M158.356 51.6367C158.722 56.364 158.356 60.7276 156.527 65.0913C153.967 73.0913 148.116 79.6367 140.802 83.6367C137.144 85.8186 133.122 86.9095 129.099 86.9095C128.002 86.9095 127.636 86.5458 127.636 85.4549C127.27 77.8186 129.83 70.5458 134.95 64.7276C140.436 57.4549 148.847 52.7276 157.99 51.6367H158.356Z" fill="black"/>
</svg>

    )
}

export default PhoneIosSvg
