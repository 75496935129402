const PhonePlaySvg = (props:{className?:string}) => {
    const {className = ''} = props

    const handleClick = () => {
        window.open('https://play.google.com/store/apps/details?id=com.openfr8.loads&hl=en_US', '_blank');
      };

    return (

<svg onClick={handleClick} className={`cursor-pointer fill-slate-500 hover:fill-white ${className}`} width={36} height={36} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 25.0811C0 11.2292 11.2292 0 25.0811 0H230.919C244.771 0 256 11.2292 256 25.0811V230.919C256 244.771 244.771 256 230.919 256H25.0811C11.2292 256 0 244.771 0 230.919V25.0811Z" fill="black"/>
<path d="M54.545 44.7197C52.555 46.7098 51.8916 50.0265 51.8916 54.0066V200.607C51.8916 204.587 53.2183 207.904 55.2084 209.894L55.8717 210.558L138.127 128.302V126.975L54.545 44.7197Z" fill="url(#paint0_linear_354_397)"/>
<path d="M164.661 156.162L137.464 128.965V126.975L164.661 99.7773L165.325 100.441L197.829 119.015C207.116 124.321 207.116 132.945 197.829 138.252L164.661 156.162Z" fill="url(#paint1_linear_354_397)"/>
<path d="M165.325 155.499L137.464 127.639L54.5454 210.558C57.8622 213.874 62.5056 213.874 68.4758 211.221L165.325 155.499Z" fill="url(#paint2_linear_354_397)"/>
<path d="M165.325 99.7769L68.4758 44.7187C62.5056 41.4019 57.8622 42.0653 54.5454 45.382L137.464 127.638L165.325 99.7769Z" fill="url(#paint3_linear_354_397)"/>
<path opacity="0.2" d="M164.662 154.836L68.4758 209.231C63.169 212.548 58.5255 211.884 55.2088 209.231L54.5454 209.894L55.2088 210.557C58.5255 213.211 63.169 213.874 68.4758 210.557L164.662 154.836Z" fill="black"/>
<path opacity="0.12" d="M54.545 209.231C52.555 207.241 51.8916 203.924 51.8916 199.944V200.607C51.8916 204.587 53.2183 207.904 55.2084 209.894V209.231H54.545ZM197.829 136.262L164.661 154.836L165.325 155.499L197.829 136.926C202.472 134.272 204.462 130.955 204.462 127.639C204.462 130.955 201.809 133.609 197.829 136.262Z" fill="black"/>
<path opacity="0.25" d="M68.4754 45.3826L197.829 119.015C201.809 121.668 204.462 124.321 204.462 127.638C204.462 124.321 202.472 121.005 197.829 118.351L68.4754 44.7193C59.1885 39.4125 51.8916 43.3926 51.8916 54.0062V54.6695C51.8916 44.7193 59.1885 40.0758 68.4754 45.3826Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M230.919 3.45946H25.0811C13.1398 3.45946 3.45946 13.1398 3.45946 25.0811V230.919C3.45946 242.86 13.1398 252.541 25.0811 252.541H230.919C242.86 252.541 252.541 242.86 252.541 230.919V25.0811C252.541 13.1398 242.86 3.45946 230.919 3.45946ZM25.0811 0C11.2292 0 0 11.2292 0 25.0811V230.919C0 244.771 11.2292 256 25.0811 256H230.919C244.771 256 256 244.771 256 230.919V25.0811C256 11.2292 244.771 0 230.919 0H25.0811Z" fill="#A6A6A6"/>
<defs>
<linearGradient id="paint0_linear_354_397" x1="130.167" y1="52.7463" x2="18.8368" y2="164.07" gradientUnits="userSpaceOnUse">
<stop stopColor="#00A0FF"/>
<stop offset="0.007" stopColor="#00A1FF"/>
<stop offset="0.26" stopColor="#00BEFF"/>
<stop offset="0.512" stopColor="#00D2FF"/>
<stop offset="0.76" stopColor="#00DFFF"/>
<stop offset="1" stopColor="#00E3FF"/>
</linearGradient>
<linearGradient id="paint1_linear_354_397" x1="209.995" y1="127.645" x2="49.4836" y2="127.645" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFE000"/>
<stop offset="0.409" stopColor="#FFBD00"/>
<stop offset="0.775" stopColor="#FFA500"/>
<stop offset="1" stopColor="#FF9C00"/>
</linearGradient>
<linearGradient id="paint2_linear_354_397" x1="150.247" y1="142.869" x2="-0.71838" y2="293.835" gradientUnits="userSpaceOnUse">
<stop stopColor="#FF3A44"/>
<stop offset="1" stopColor="#C31162"/>
</linearGradient>
<linearGradient id="paint3_linear_354_397" x1="33.9616" y1="-3.86517" x2="101.378" y2="63.5513" gradientUnits="userSpaceOnUse">
<stop stopColor="#32A071"/>
<stop offset="0.069" stopColor="#2DA771"/>
<stop offset="0.476" stopColor="#15CF74"/>
<stop offset="0.801" stopColor="#06E775"/>
<stop offset="1" stopColor="#00F076"/>
</linearGradient>
</defs>
</svg>

    )
}

export default PhonePlaySvg
