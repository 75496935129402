
const LinkedInSvg = () => {


    const handleClick = () => {
        window.open('https://www.linkedin.com/company/openfr8/', '_blank');
    };

    return (
        <svg onClick={handleClick} className='cursor-pointer fill-slate-500 hover:fill-white' width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.8945 0H2.18013C1.63182 0.00395063 1.10753 0.225487 0.72252 0.615901C0.337511 1.00632 0.123306 1.53365 0.127 2.08195V26.7451C0.123306 27.2934 0.337511 27.8208 0.72252 28.2112C1.10753 28.6016 1.63182 28.8231 2.18013 28.8271H26.8945C27.4435 28.8234 27.9686 28.6022 28.3547 28.2119C28.7408 27.8216 28.9563 27.2941 28.9541 26.7451V2.08195C28.9563 1.53297 28.7408 1.00549 28.3547 0.615201C27.9686 0.224911 27.4435 0.0036703 26.8945 0ZM8.83918 24.7091H4.5685V10.9522H8.84559V24.7091H8.83918ZM6.70384 9.07305C6.21394 9.07305 5.73504 8.92778 5.3277 8.6556C4.92036 8.38343 4.60288 7.99657 4.4154 7.54396C4.22792 7.09135 4.17887 6.59331 4.27444 6.11282C4.37002 5.63233 4.60593 5.19098 4.95234 4.84456C5.29876 4.49815 5.74012 4.26224 6.2206 4.16666C6.70109 4.07109 7.19913 4.12014 7.65175 4.30762C8.10436 4.49509 8.49121 4.81258 8.76339 5.21992C9.03556 5.62726 9.18083 6.10616 9.18083 6.59606C9.18055 7.2531 8.91935 7.88313 8.45465 8.34762C7.98995 8.81212 7.35981 9.07305 6.70277 9.07305H6.70384ZM24.8542 24.7091H20.5835V18.0169C20.5835 16.4208 20.5515 14.3687 18.3639 14.3687C16.1378 14.3687 15.8015 16.1058 15.8015 17.9016V24.7091H11.5308V10.9522H15.6296V12.8312H15.6872C16.0985 12.1302 16.692 11.5537 17.4047 11.1629C18.1173 10.7721 18.9225 10.5816 19.7347 10.6116C24.0588 10.6116 24.8596 13.4622 24.8596 17.1681L24.8542 24.7091Z" />
        </svg>
    )
}

export default LinkedInSvg