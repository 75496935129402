const Login = (props:{className?:string}) => {
    const {className = ''} = props

    const handleClick = () => {
        window.open('https://openfr8.com/app/login', '_blank');
      };

    return (

<svg onClick={handleClick} className={`cursor-pointer fill-slate-500 hover:fill-white ${className}`} width={36} height={36} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M128.001 162.239C156.22 162.239 179.096 138.891 179.096 110.091C179.096 81.2899 156.22 57.9424 128.001 57.9424C99.7821 57.9424 76.9062 81.2899 76.9062 110.091C76.9062 138.891 99.7821 162.239 128.001 162.239Z" fill="black"/>
<path d="M128 0C57.307 0 0 57.307 0 128C0 198.693 57.307 256 128 256C198.693 256 256 198.693 256 128C256 57.307 198.693 0 128 0ZM206.561 206.242C189.796 189.958 160.876 179.2 128 179.2C95.1239 179.2 66.2044 189.958 49.4393 206.242C29.4457 186.166 17.0667 158.504 17.0667 128C17.0667 66.8302 66.8302 17.0667 128 17.0667C189.17 17.0667 238.933 66.8302 238.933 128C238.933 158.504 226.554 186.166 206.561 206.242Z" fill="black"/>
</svg>

    )
}

export default Login